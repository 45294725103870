var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                    staticClass: "marT10",
                  },
                  [
                    _c("div", { staticClass: "x-f" }, [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("商品"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listGoods" }).option,
                            },
                            model: {
                              value: _vm.queryParams.goodsIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "goodsIds", $$v)
                              },
                              expression: "queryParams.goodsIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("商品类别"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listCategory" })
                                .option,
                            },
                            model: {
                              value: _vm.queryParams.categoryIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "categoryIds", $$v)
                              },
                              expression: "queryParams.categoryIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("入库仓库"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listStore" }).option,
                            },
                            model: {
                              value: _vm.queryParams.storeIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "storeIds", $$v)
                              },
                              expression: "queryParams.storeIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("生产工厂"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceDept",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceDeptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "produceDeptIds", $$v)
                              },
                              expression: "queryParams.produceDeptIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("部门"),
                          ]),
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listSimpleQuery",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.deptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "deptIds", $$v)
                              },
                              expression: "queryParams.deptIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "产品入库汇总表" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "仓库汇总查询", name: "warehouse" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable1",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "入库仓库",
                                align: "center",
                                prop: "storeName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "unitName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "入库数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "合格数量",
                                sortable: "",
                                align: "center",
                                prop: "unitEligibleQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产次品数量",
                                sortable: "",
                                align: "center",
                                prop: "unitProduceInferiorQty",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "包装次品数量",
                                sortable: "",
                                align: "center",
                                prop: "unitPackInferiorQty",
                                "min-width": "130",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "商品汇总查询", name: "goods" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable2",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品类别",
                                align: "center",
                                prop: "categoryName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "unitName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "入库数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "合格数量",
                                sortable: "",
                                align: "center",
                                prop: "unitEligibleQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产次品数量",
                                sortable: "",
                                align: "center",
                                prop: "unitProduceInferiorQty",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "包装次品数量",
                                sortable: "",
                                align: "center",
                                prop: "unitPackInferiorQty",
                                "min-width": "130",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "每日产品入库按商品汇总查询",
                          name: "dayGoods",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable3",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "日期",
                                align: "center",
                                prop: "billDate",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "unitName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "入库数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "合格数量",
                                sortable: "",
                                align: "center",
                                prop: "unitEligibleQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产次品数量",
                                sortable: "",
                                align: "center",
                                prop: "unitProduceInferiorQty",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "包装次品数量",
                                sortable: "",
                                align: "center",
                                prop: "unitPackInferiorQty",
                                "min-width": "130",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "商品入库（生产工厂+仓库）汇总查询",
                          name: "cashier",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable4",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产工厂",
                                align: "center",
                                prop: "produceDeptName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "部门",
                                align: "center",
                                prop: "deptName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "入库仓库",
                                align: "center",
                                prop: "storeName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "unitName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    "is-select-all": false,
                    "total-com": _vm.total,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }