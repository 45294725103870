<template>
  <DssssseliverLine />
</template>

<script>
import DssssseliverLine from '@/views/system/partner/deliverLine'
export default {
  name: 'DssssseliverLine',
  components: { DssssseliverLine }
}
</script>
