<template>
  <div class="wrap" id="wrap" v-loading="loading">
    <!-- 表格和搜索右边盒子 -->
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            @isShowHighCom="getIsShowHigh"
          />
          <!-- 条件 -->
          <div v-show="showHigh" class="marT10">
            <div class="x-f">
              <div class="x-f">
                <span class="fS14MR10">商品</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.goodsIds"
                  style="width: 250px"
                  :option="$select({ key: 'listGoods' }).option"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">商品类别</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.categoryIds"
                  style="width: 250px"
                  :option="$select({ key: 'listCategory' }).option"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">入库仓库</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.storeIds"
                  style="width: 250px"
                  :option="$select({ key: 'listStore' }).option"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">生产工厂</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.produceDeptIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceDept'
                    }).option
                  "
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">部门</span>
                <SelectLocal
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.deptIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listSimpleQuery'
                    }).option
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="产品入库汇总表" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="仓库汇总查询" name="warehouse">
              <el-table
                ref="multipleTable1"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="入库仓库"
                  align="center"
                  prop="storeName"
                  min-width="160"
                />

                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  min-width="160"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  min-width="160"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  min-width="120"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  min-width="120"
                />
                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  min-width="120"
                />
                <el-table-column
                  label="入库数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  min-width="120"
                />
                <el-table-column
                  label="合格数量"
                  sortable
                  align="center"
                  prop="unitEligibleQty"
                  min-width="120"
                />
                <el-table-column
                  label="生产次品数量"
                  sortable
                  align="center"
                  prop="unitProduceInferiorQty"
                  min-width="130"
                />
                <el-table-column
                  label="包装次品数量"
                  sortable
                  align="center"
                  prop="unitPackInferiorQty"
                  min-width="130"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="商品汇总查询" name="goods">
              <el-table
                ref="multipleTable2"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="商品类别"
                  align="center"
                  prop="categoryName"
                  min-width="160"
                />

                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  min-width="160"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  min-width="160"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  min-width="160"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  min-width="130"
                />
                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  min-width="120"
                />
                <el-table-column
                  label="入库数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  min-width="120"
                />
                <el-table-column
                  label="合格数量"
                  sortable
                  align="center"
                  prop="unitEligibleQty"
                  min-width="120"
                />
                <el-table-column
                  label="生产次品数量"
                  sortable
                  align="center"
                  prop="unitProduceInferiorQty"
                  min-width="130"
                />
                <el-table-column
                  label="包装次品数量"
                  sortable
                  align="center"
                  prop="unitPackInferiorQty"
                  min-width="130"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="每日产品入库按商品汇总查询" name="dayGoods">
              <el-table
                ref="multipleTable3"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="日期"
                  align="center"
                  prop="billDate"
                  min-width="120"
                />

                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  min-width="160"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  min-width="160"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  min-width="160"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  min-width="160"
                />

                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  min-width="120"
                />
                <el-table-column
                  label="入库数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  min-width="120"
                />
                <el-table-column
                  label="合格数量"
                  sortable
                  align="center"
                  prop="unitEligibleQty"
                  min-width="120"
                />
                <el-table-column
                  label="生产次品数量"
                  sortable
                  align="center"
                  prop="unitProduceInferiorQty"
                  min-width="130"
                />
                <el-table-column
                  label="包装次品数量"
                  sortable
                  align="center"
                  prop="unitPackInferiorQty"
                  min-width="130"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="商品入库（生产工厂+仓库）汇总查询"
              name="cashier"
            >
              <el-table
                ref="multipleTable4"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="生产工厂"
                  align="center"
                  prop="produceDeptName"
                />
                <el-table-column label="部门" align="center" prop="deptName" />
                <el-table-column
                  label="入库仓库"
                  align="center"
                  prop="storeName"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  show-overflow-tooltip
                />
                <el-table-column label="规格" align="center" prop="goodsSpec" />
                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                />
                <el-table-column
                  label="基本单位数量"
                  sortable
                  align="center"
                  prop="unitQty"
                />
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import {
  storeAPI,
  goodsAPI,
  dayGoodsAPI,
  deptStoreAPI
} from '@/api/produce/report/inStoreTotal' //生产入库汇总表
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'inStoreTotal',
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    SelectLocal,
    SelectRemote
  },
  data () {
    return {
      activeName: 'warehouse',
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'storeNames', name: '入库仓库' },
        { parameter: 'goodsNos', name: '商品编码' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' }
      ],
      // 是否显示高级搜索
      showHigh: false,
      // 表格遮罩
      loadingTable: false,
      //全局加载
      loading: false,
      tableData: [], // 配方表格
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      activeNameType: {
        warehouse: 'multipleTable1',
        goods: 'multipleTable2',
        dayGoods: 'multipleTable3',
        cashier: 'multipleTable4'
      }, //枚举
      exportType: {
        warehouse: 'api/system/produce/storage/report/exportStorageByStore',
        goods: 'api/system/produce/storage/report/exportStorageByGoods',
        dayGoods: 'api/system/produce/storage/report/exportStorageDateByGoods',
        cashier: 'api/system/produce/storage/report/exportStorageByDeptStore'
      }, //定义导出枚举
      exportNameType: {
        warehouse: '产品入库汇总表（仓库汇总查询）',
        goods: '产品入库汇总表（商品汇总查询）',
        dayGoods: '产品入库汇总表（每日产品入库按商品汇总查询）',
        cashier: '产品入库汇总表（商品入库（生产工厂+仓库）汇总查询）'
      }, //定义导出名称枚举
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs[this.activeNameType[this.activeName]].doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    // 分页
    async handleClick (tab) {
      await this.getList()
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      let res = {}
      if (this.activeName == 'warehouse') {
        res = await storeAPI(this.queryParams)
      } else if (this.activeName == 'goods') {
        res = await goodsAPI(this.queryParams)
      } else if (this.activeName == 'dayGoods') {
        res = await dayGoodsAPI(this.queryParams)
      } else {
        res = await deptStoreAPI(this.queryParams)
      }

      this.tableData = res.rows
      this.total = res.total
      this.loadingTable = false
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //计算
    getSummaries (param) {
      console.log(param)
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        let name = ''
        if (column.property === 'unitQty') {
          name = 'unitQty'

          sums[index] = 0 // 初始化合计值为0
          // sums[index] += " ";

          const values = data.map(item => item[name])

          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr
          }, sums[index]) // 累加到已初始化的合计值上
        } else if (column.property === 'unitEligibleQty') {
          name = 'unitEligibleQty'

          sums[index] = 0 // 初始化合计值为0
          //sums[index] += " ";

          const values = data.map(item => item[name])
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr
          }, sums[index]) // 累加到已初始化的合计值上
        } else if (column.property === 'unitProduceInferiorQty') {
          name = 'unitProduceInferiorQty'

          sums[index] = 0 // 初始化合计值为0
          //sums[index] += " ";

          const values = data.map(item => item[name])
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr
          }, sums[index]) // 累加到已初始化的合计值上
        } else if (column.property === 'unitPackInferiorQty') {
          name = 'unitPackInferiorQty'

          sums[index] = 0 // 初始化合计值为0
          //sums[index] += " ";

          const values = data.map(item => item[name])
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr
          }, sums[index]) // 累加到已初始化的合计值上
        }
        // sums[index] = sums[index].toFixed(2); // 保留两位小数
      })
      return sums
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 96px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
