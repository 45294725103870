var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title":
                      "输入单据编号、商品名称、商品编码、条码",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "marT10 x-f" }, [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("单据类型"),
                          ]),
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: {
                                data: _vm.billTypeList,
                                labels: [
                                  { title: "名称", label: "billTypeName" },
                                ],
                                value: "billTypeId",
                                label: "billTypeName",
                              },
                            },
                            model: {
                              value: _vm.queryParams.billTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "billTypes", $$v)
                              },
                              expression: "queryParams.billTypes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("领料/退料工厂"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceDept",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceDeptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "produceDeptIds", $$v)
                              },
                              expression: "queryParams.produceDeptIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("领料/退料部门"),
                          ]),
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listSimpleQuery",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.deptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "deptIds", $$v)
                              },
                              expression: "queryParams.deptIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("领料/退料仓库"),
                          ]),
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listStore" }).option,
                            },
                            model: {
                              value: _vm.queryParams.storeIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "storeIds", $$v)
                              },
                              expression: "queryParams.storeIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "x-f marT10" }, [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10 marL30" }, [
                            _vm._v("商品"),
                          ]),
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listGoods" }).option,
                            },
                            model: {
                              value: _vm.queryParams.goodsIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "goodsIds", $$v)
                              },
                              expression: "queryParams.goodsIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fS14MR10",
                              staticStyle: { "margin-left": "60px" },
                            },
                            [_vm._v("创建人")]
                          ),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listEmployee" })
                                .option,
                            },
                            model: {
                              value: _vm.queryParams.createEmployeeIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "createEmployeeIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.createEmployeeIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fS14MR10",
                              staticStyle: { "margin-left": "45px" },
                            },
                            [_vm._v("审核人")]
                          ),
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listEmployee" })
                                .option,
                            },
                            model: {
                              value: _vm.queryParams.auditEmployeeIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "auditEmployeeIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.auditEmployeeIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "生产领料明细表" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "tableContent" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        "show-summary": "",
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "单据编号",
                          align: "center",
                          prop: "billNo",
                          "min-width": "160",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据日期",
                          align: "center",
                          prop: "billDate",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "单据类型",
                          align: "center",
                          prop: "billTypeName",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "退料/领料工厂",
                          align: "center",
                          prop: "produceDeptName",
                          "min-width": "140",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "退料/领料部门",
                          align: "center",
                          prop: "deptName",
                          "min-width": "140",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "退料/领料仓库",
                          align: "center",
                          prop: "storeName",
                          "min-width": "140",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          align: "center",
                          prop: "goodsNo",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          prop: "goodsName",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "条码",
                          align: "center",
                          prop: "barcode",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "goodsSpec",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          prop: "unitName",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "需求数量",
                          align: "center",
                          prop: "unitNeedQty",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.unitNeedQty || 0)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "领料/退料数量",
                          sortable: "",
                          align: "center",
                          prop: "unitQty",
                          "min-width": "140",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        scope.row.billTypeName == "生产退料"
                                          ? "#ff4949"
                                          : "",
                                      fontWeight:
                                        scope.row.billTypeName == "生产退料"
                                          ? "bold"
                                          : "",
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.unitQty || 0))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "计划外数量",
                          sortable: "",
                          align: "center",
                          prop: "unitOutsidePlanQty",
                          "min-width": "130",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.unitOutsidePlanQty || 0)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "基本单位",
                          align: "center",
                          prop: "basUnitName",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.basUnitName || 0)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "需求基本数量",
                          sortable: "",
                          align: "center",
                          prop: "basUnitNeedQty",
                          "min-width": "125",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.basUnitNeedQty || 0)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "退料/领料基本数量",
                          sortable: "",
                          align: "center",
                          prop: "basUnitQty",
                          "min-width": "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.basUnitQty || 0)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "计划外基本数量",
                          sortable: "",
                          align: "center",
                          prop: "basUnitOutsidePlanCostPrice",
                          "min-width": "140",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.basUnitOutsidePlanCostPrice || 0
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "基本进价",
                          sortable: "",
                          align: "center",
                          prop: "basUnitPurPrice",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "基本售价",
                          sortable: "",
                          align: "center",
                          prop: "basUnitSalePrice",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "基本进价金额",
                          sortable: "",
                          align: "center",
                          prop: "basUnitPurMoney",
                          "min-width": "140",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.basUnitPurMoney || 0)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "基本售价金额",
                          sortable: "",
                          align: "center",
                          prop: "basUnitSaleMoney",
                          "min-width": "140",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.basUnitSaleMoney || 0)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位进价",
                          align: "center",
                          prop: "unitPurPrice",
                          "min-width": "120",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位售价",
                          align: "center",
                          prop: "unitSalePrice",
                          sortable: "",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位进价金额",
                          align: "center",
                          prop: "unitPurMoney",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.unitPurMoney || 0)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位售价金额",
                          align: "center",
                          prop: "unitSaleMoney",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.unitSaleMoney || 0)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "制单人",
                          align: "center",
                          prop: "createBy",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核人",
                          align: "center",
                          prop: "auditBy",
                          "min-width": "120",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      "is-select-all": false,
                      "total-com": _vm.total,
                    },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }