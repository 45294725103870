var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap x-f", attrs: { id: "wrap" } },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "生产工厂",
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.produceDeptList,
          defaultProps: _vm.defaultProps,
        },
        on: { treeNodeId: _vm.getTreeNodeId },
      }),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c(
            "cardTitleCom",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
              attrs: { cardTitle: "生产班次信息" },
              scopedSlots: _vm._u([
                {
                  key: "rightCardTitle",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "marR10",
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("保存 ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { rules: _vm.rules, model: _vm.form },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: {
                              "cell-mouse-enter": _vm.cellMouseEnter,
                              "cell-mouse-leave": _vm.cellMouseLeave,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.tableCellLabel,
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus operatePush",
                                            on: {
                                              click: function ($event) {
                                                return _vm.row(
                                                  "push",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-remove operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.row(
                                                  "del",
                                                  scope.$index,
                                                  scope.row.produceOrderId
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      !scope.row.hoverRow
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.$index + 1)),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产班次编号",
                                align: "center",
                                prop: "produceOrderNo",
                                "min-width": "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableData." +
                                              scope.$index +
                                              ".produceOrderNo",
                                            rules: _vm.rules["produceOrderNo"],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "inputWith",
                                            attrs: {
                                              size: "mini",
                                              maxlength: "20",
                                            },
                                            model: {
                                              value: scope.row.produceOrderNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "produceOrderNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.produceOrderNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产班次名称",
                                align: "center",
                                prop: "produceOrderName",
                                "min-width": "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableData." +
                                              scope.$index +
                                              ".produceOrderName",
                                            rules:
                                              _vm.rules["produceOrderName"],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "inputWith",
                                            attrs: {
                                              size: "mini",
                                              maxlength: "40",
                                            },
                                            model: {
                                              value: scope.row.produceOrderName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "produceOrderName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.produceOrderName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "开始时间",
                                align: "center",
                                prop: "produceOrderBegTime",
                                "min-width": "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableData." +
                                              scope.$index +
                                              ".produceOrderBegTime",
                                            rules:
                                              _vm.rules["produceOrderBegTime"],
                                          },
                                        },
                                        [
                                          _c("el-time-picker", {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择开始时间",
                                              "value-format": "HH:mm:ss",
                                            },
                                            model: {
                                              value:
                                                scope.row.produceOrderBegTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "produceOrderBegTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.produceOrderBegTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "结束时间",
                                align: "center",
                                prop: "produceOrderEndTime",
                                "min-width": "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableData." +
                                              scope.$index +
                                              ".produceOrderEndTime",
                                            rules:
                                              _vm.rules["produceOrderEndTime"],
                                          },
                                        },
                                        [
                                          _c("el-time-picker", {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择结束时间",
                                              "value-format": "HH:mm:ss",
                                            },
                                            model: {
                                              value:
                                                scope.row.produceOrderEndTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "produceOrderEndTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.produceOrderEndTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }