<template>
  <!-- 用户列表 -->
  <div class="wrap">
    <el-form :model="options">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </el-form>
    <c-dialog title="批量修改" :width="500" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" label-width='120px'>
          <el-form-item label="是否锁定配货">
            <el-switch v-model="form.isLockDeliver" :active-value="true" :inactive-value="false" />
          </el-form-item>
          <el-form-item label="配送路线">
            <SelectLocal v-model="form.deliverLineId" :option="$select({
                    key: 'listDeliverLine',
                  }).option
                " />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleEvent('submitModification')">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import {
  listDeliverObject, updateDeliverLineDistributionInfo
} from '@/api/produce/sets/autoDistributeObject' //生产工厂
import TablePage from '@/components/tablePage/index.vue'
export default {
  name: 'RetailTotal',
  components: {
    CDialog,
    TablePage,
    SelectLocal
  },
  data() {
    return {
      form: {
        isLockDeliver: true
      },
      dialogFormVisible: false,
      options: {
        mutiSelect: true,
        loading: true,
        check: [],
        // mutiSelect: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          productName: undefined, //用户名称
          status: undefined //状态
        },
        defaultBody: {
          delFlag: 0
        },
        getListApi: listDeliverObject,
        getDataKey: { list: "data" },
        pagination: {
          onlySelectTotal: true
        },
        title: '配货对象',
        rowKey: 'id',
        search: [
          {
            type: 'filters',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'nos', label: '客户编号' },
              { filter: 'names', label: '客户名称' },
              { filter: 'deliverLineNames', label: '配送路线名称' },
            ]
          },
          this.$select({
            key: "listDeliverLine",
            option: {
              filter: "deliverLineIds",
              option: {
                multiple: true,
              },
            },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'batchModification',
            type: 'primary',
            label: '批量修改',
          },
          {
            click: 'add',
            type: 'primary',
            label: '保存',
            icon: 'el-icon-plus',
            noRule: true,
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'no',
            label: '客户编号',
            minWidth: '160'
          },
          {
            prop: 'name',
            label: '客户名称',
            minWidth: '160'
          },
          {
            prop: 'type',
            label: '是否客户',
            minWidth: '120',
            formatter: (v, row) => row.type == 1 ? '是' : '否',
          },
          {
            prop: 'isLockDeliver',
            type: 'switch',
            // click: 'switch',
            label: '是否锁定配货',
            active: true,
            inactive: false,
            disabled: (v, row) => row.subType != 0,
            minWidth: '120'
          },
          { prop: 'deliverSortNo', label: '排序', minWidth: '120', type: 'my-input', },
          {
            prop: 'deliverLineId',
            label: '配送路线',
            showItem: {
              optionId: "lineId",
              optionName: "lineName",
              prop: 'deliverLineId',
              label: 'deliverLineName',
            }, minWidth: '160', type: 'localSelect', option: this.$select({
              key: "listDeliverLine",
            }).option,
            // rules: true
          }
        ],
        list: []
      },
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'add':
          await updateDeliverLineDistributionInfo({ deliverInfo: this.options.list })
          await this.$refs.tablePage.getList()
          this.$message.success('保存成功')
          break
        case 'batchModification':
          this.dialogFormVisible = true
          break
        case 'submitModification':
          let checkArray = this.options.check.map(v => {
            if (v.subType == 0) {
              v.isLockDeliver = this.form.isLockDeliver
            }
            v.deliverLineId = this.form.deliverLineId
            return v
          })
          await updateDeliverLineDistributionInfo({ deliverInfo: checkArray })
          this.cancel()
          await this.$refs.tablePage.getList()
          this.$message.success('批量修改成功')
          break
        default:
          break
      }
    },
    cancel() {
      this.dialogFormVisible = false
      this.form = {
        isLockDeliver: true
      }
    }
  }
}
</script>
