<template>
  <div class="wrap" id="wrap">
    <!-- 表格和搜索右边盒子 -->

    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="输入单据编号、入库仓库、商品编码、商品名称、条码"
            @isShowHighCom="getIsShowHigh"
          />
          <!-- 条件 -->
          <div v-show="showHigh" class="marT10">
            <div class="x-f">
              <div class="x-f">
                <span class="fS14MR10">商品</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.goodsIds"
                  style="width: 250px"
                  :option="$select({ key: 'listGoods' }).option"
                />
              </div>

              <div class="x-f">
                <span class="fS14MR10">入库仓库</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.storeIds"
                  style="width: 250px"
                  :option="$select({ key: 'listStore' }).option"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">生产工厂</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.produceDeptIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceDept'
                    }).option
                  "
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">部门</span>
                <SelectLocal
                  :multiple="true"
                  v-model="queryParams.deptIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listSimpleQuery'
                    }).option
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="产品入库明细表" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <div>
            <el-table
              ref="multipleTable"
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="tableData"
              show-summary
              border
              :max-height="tableHeight"
              :height="tableHeight"
              :summary-method="getSummaries"
            >
              <el-table-column
                label="序号"
                align="center"
                width="80"
                type="index"
              />
              <el-table-column
                label="单据日期"
                align="center"
                min-width="125"
                prop="billDate"
              />
              <el-table-column
                label="单据编号"
                align="center"
                min-width="160"
                prop="billNo"
                show-overflow-tooltip
              />

              <el-table-column
                label="入库仓库"
                align="center"
                min-width="120"
                prop="storeName"
              />
              <el-table-column
                label="生产工厂"
                align="center"
                min-width="120"
                prop="produceDeptName"
              />
              <el-table-column
                label="部门"
                align="center"
                min-width="120"
                prop="deptName"
              />
              <el-table-column
                label="商品类别"
                align="center"
                min-width="120"
                prop="categoryName"
              />
              <el-table-column
                label="商品编码"
                align="center"
                min-width="120"
                prop="goodsNo"
                show-overflow-tooltip
              />
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsName"
                min-width="120"
              />
              <el-table-column
                label="条码"
                align="center"
                min-width="120"
                show-overflow-tooltip
                prop="barcode"
              />
              <el-table-column
                label="规格"
                align="center"
                prop="goodsSpec"
                min-width="120"
              />
              <el-table-column
                label="单位"
                align="center"
                prop="unitName"
                min-width="120"
              />
              <el-table-column
                label="入库数量"
                align="center"
                prop="unitQty"
                min-width="120"
                sortable
              />
              <el-table-column
                label="进价"
                align="center"
                sortable
                prop="unitPurPrice"
                min-width="120"
              />
              <el-table-column
                label="售价"
                sortable
                align="center"
                prop="unitSalePrice"
                min-width="120"
              />
              <el-table-column
                label="进价金额(入库)"
                sortable
                align="center"
                prop="unitPurMoney"
                min-width="140"
              />
              <el-table-column
                label="售价金额(入库)"
                sortable
                align="center"
                prop="unitSaleMoney"
                min-width="140"
              />
              <el-table-column
                label="合格数量"
                sortable
                align="center"
                prop="unitEligibleQty"
                min-width="120"
              />
              <el-table-column
                label="进价金额（合格）"
                sortable
                align="center"
                prop="unitPurMoneyEligible"
                min-width="160"
              />
              <el-table-column
                label="售价金额（合格）"
                sortable
                align="center"
                prop="unitSaleMoneyEligible"
                min-width="150"
              />
              <el-table-column
                label="生产次品"
                sortable
                align="center"
                prop="unitProduceInferiorQty"
                min-width="120"
              />
              <el-table-column
                label="进价金额（生产次品）"
                sortable
                align="center"
                prop="unitPurMoneyProduceInferior"
                min-width="180"
              />

              <el-table-column
                label="售价金额（生产次品）"
                sortable
                align="center"
                prop="unitSaleMoneyProduceInferior"
                min-width="180"
              />
              <el-table-column
                label="包装次品"
                align="center"
                min-width="120"
                prop="unitPackInferiorQty"
              />
              <el-table-column
                label="进价金额（包装次品）"
                sortable
                align="center"
                prop="unitPurMoneyPackInferior"
                min-width="180"
              />
              <el-table-column
                label="售价金额（包装次品）"
                sortable
                align="center"
                prop="unitSaleMoneyPackInferior"
                min-width="180"
              />

              <el-table-column
                label="审核人"
                align="center"
                min-width="120"
                prop="auditBy"
              />
              <el-table-column
                label="审核时间"
                align="center"
                prop="auditTime"
                min-width="160"
                show-overflow-tooltip
              />
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :is-select-all="false"
              :total-com="total"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import { storeDetailAPI } from '@/api/produce/report/inStoreDetail' //生产入库明细列表
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'inStoreDetail',
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    SelectLocal,
    SelectRemote
  },
  data () {
    return {
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'billNos', name: '单据编号' },
        { parameter: 'storeNames', name: '入库仓库' },
        { parameter: 'goodsNos', name: '商品编码' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' }
      ],
      // 是否显示高级搜索
      showHigh: false,
      // 表格遮罩
      loadingTable: false,
      tableData: [], // 表格
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 74
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/produce/storage/report/exportStorageDetails',
        {
          ...this.queryParams
        },
        `产品入库明细表导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await storeDetailAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //计算
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (
          index <= 10 ||
          index == 12 ||
          index == 13 ||
          index == 25 ||
          index == 26
        ) {
          sums[index] = ''
          return
        }
        let values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${Number(sums[index].toFixed(2))} `
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
