var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TablePage", {
        ref: "tablePage",
        staticClass: "tablepage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.title,
          width: 450,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dialogLoading,
                        expression: "dialogLoading",
                      },
                    ],
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                            }).option,
                          },
                          model: {
                            value: _vm.form.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "produceDeptId", $$v)
                            },
                            expression: "form.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "生产仓库编号",
                          prop: "produceStoreNo",
                        },
                      },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listStore",
                              option: {
                                option: {
                                  remoteBody: { storeTypes: [0] },
                                  label: "storeNo",
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.selectChangeGoods },
                          model: {
                            value: _vm.form.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "storeId", $$v)
                            },
                            expression: "form.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "生产仓库名称",
                          prop: "produceStoreName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            size: "mini",
                            disabled: "",
                            autocomplete: "off",
                            placeholder: "请输入生产仓库名称",
                          },
                          model: {
                            value: _vm.form.produceStoreName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "produceStoreName", $$v)
                            },
                            expression: "form.produceStoreName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产仓库属性", prop: "storeType" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth",
                            attrs: { placeholder: "请选择生产仓库属性" },
                            model: {
                              value: _vm.form.storeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "storeType", $$v)
                              },
                              expression: "form.storeType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "成品仓", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "半品仓", value: 2 },
                            }),
                            _c("el-option", {
                              attrs: { label: "原料仓", value: 3 },
                            }),
                            _c("el-option", {
                              attrs: { label: "配料间", value: 4 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.titleBatch,
          width: 450,
          showDialog: _vm.dialogFormVisibleBatch,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisibleBatch = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisibleBatch = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  { attrs: { model: _vm.formBatch, "label-width": "120px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                            }).option,
                          },
                          model: {
                            value: _vm.formBatch.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formBatch, "produceDeptId", $$v)
                            },
                            expression: "formBatch.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产仓库属性", prop: "storeType" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth",
                            attrs: { placeholder: "请选择生产仓库属性" },
                            model: {
                              value: _vm.formBatch.storeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formBatch, "storeType", $$v)
                              },
                              expression: "formBatch.storeType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "成品仓", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "半品仓", value: 2 },
                            }),
                            _c("el-option", {
                              attrs: { label: "原料仓", value: 3 },
                            }),
                            _c("el-option", {
                              attrs: { label: "配料间", value: 4 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisibleBatch = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitFormBatch },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }