<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="商品编码、商品名称、条码"
            @isShowHighCom="getIsShowHigh"
          />

          <div v-show="showHigh">
            <div class="marT10 x-f">
              <div class="x-f">
                <span class="fS14MR10">单据类型</span>
                <SelectLocal
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.billTypes"
                  style="width: 250px"
                  :option="{
                    data: billTypeList,
                    labels: [{ title: '名称', label: 'billTypeName' }],
                    value: 'billTypeId',
                    label: 'billTypeName'
                  }"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">领料/退料工厂</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.produceDeptIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceDept'
                    }).option
                  "
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">领料/退料部门</span>
                <SelectLocal
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.deptIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listSimpleQuery'
                    }).option
                  "
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">领料/退料仓库</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.storeIds"
                  style="width: 250px"
                  :option="$select({ key: 'listStore' }).option"
                />
              </div>
            </div>
            <div class="x-f marT10">
              <div class="x-f">
                <span class="fS14MR10 marL30">商品</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.goodsIds"
                  style="width: 250px"
                  :option="$select({ key: 'listGoods' }).option"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10" style="margin-left: 60px">创建人</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.createEmployeeIds"
                  style="width: 250px"
                  :option="$select({ key: 'listEmployee' }).option"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10" style="margin-left: 45px">审核人</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.auditEmployeeIds"
                  style="width: 250px"
                  :option="$select({ key: 'listEmployee' }).option"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="生产领料汇总表" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="商品汇总查询" name="goods">
              <el-table
                ref="multipleTable1"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据类型"
                  align="center"
                  prop="billTypeName"
                />

                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  min-width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  min-width="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  min-width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  min-width="120"
                />

                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  min-width="120"
                />
                <el-table-column
                  label="需求数量"
                  sortable
                  align="center"
                  prop="unitNeedQty"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.unitNeedQty || 0 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="领料/退料数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  min-width="140"
                >
                  <template slot-scope="scope">
                    <span
                      :style="{
                        color:
                          scope.row.billTypeName == '生产退料' ? '#ff4949' : '',
                        fontWeight:
                          scope.row.billTypeName == '生产退料' ? 'bold' : ''
                      }"
                      >{{ scope.row.unitQty || 0 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="计划外数量"
                  sortable
                  align="center"
                  prop="unitOutsidePlanQty"
                  min-width="150"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.unitOutsidePlanQty || 0 }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="进价"
                  sortable
                  align="center"
                  prop="unitPurPrice"
                  min-width="120"
                />
                <el-table-column
                  label="售价"
                  sortable
                  align="center"
                  prop="unitSalePrice"
                  min-width="120"
                />
                <el-table-column
                  label="进价金额"
                  sortable
                  align="center"
                  prop="unitPurMoney"
                  min-width="150"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="unitSaleMoney"
                  min-width="120"
                />
                <el-table-column
                  label="需求成本"
                  sortable
                  align="center"
                  prop="unitNeedCostPrice"
                  min-width="120"
                />
                <el-table-column
                  label="领用成本"
                  sortable
                  align="center"
                  prop="unitCostPrice"
                  min-width="120"
                />

                <el-table-column
                  label="计划外成本"
                  sortable
                  align="center"
                  prop="unitOutsidePlanCostPrice"
                  min-width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="仓库汇总查询" name="store">
              <el-table
                ref="multipleTable2"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据类型"
                  align="center"
                  prop="billTypeName"
                />
                <el-table-column
                  label="领料/退料仓库"
                  align="center"
                  prop="storeName"
                  width="120"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="120"
                />

                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  width="120"
                />
                <el-table-column
                  label="需求数量"
                  sortable
                  align="center"
                  prop="unitNeedQty"
                  width="120"
                />
                <el-table-column
                  label="领用数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  width="120"
                />
                <el-table-column
                  label="计划外数量"
                  sortable
                  align="center"
                  prop="unitOutsidePlanQty"
                  width="150"
                />

                <el-table-column
                  label="进价"
                  sortable
                  align="center"
                  prop="unitPurPrice"
                  width="120"
                />
                <el-table-column
                  label="售价"
                  sortable
                  align="center"
                  prop="unitSalePrice"
                  width="120"
                />
                <el-table-column
                  label="进价金额"
                  sortable
                  align="center"
                  prop="unitPurMoney"
                  width="150"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="unitSaleMoney"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="生产工厂汇总查询" name="dept">
              <el-table
                ref="multipleTable3"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据类型"
                  align="center"
                  prop="billTypeName"
                />
                <el-table-column
                  label="退料/领料工厂"
                  align="center"
                  prop="produceDeptName"
                  min-width="140"
                />
                <el-table-column
                  label="退料/领料部门"
                  align="center"
                  prop="deptName"
                  min-width="140"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="120"
                />

                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  width="120"
                />
                <el-table-column
                  label="需求数量"
                  sortable
                  align="center"
                  prop="unitNeedQty"
                  width="120"
                />
                <el-table-column
                  label="领用数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  width="120"
                />
                <el-table-column
                  label="计划外数量"
                  sortable
                  align="center"
                  prop="unitOutsidePlanQty"
                  width="150"
                />

                <el-table-column
                  label="进价"
                  sortable
                  align="center"
                  prop="unitPurPrice"
                  width="120"
                />
                <el-table-column
                  label="售价"
                  sortable
                  align="center"
                  prop="unitSalePrice"
                  width="120"
                />
                <el-table-column
                  label="进价金额"
                  sortable
                  align="center"
                  prop="unitPurMoney"
                  width="150"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="unitSaleMoney"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  summaryByGoodsAPI,
  summaryByStoreAPI,
  summaryByDeptAPI
} from '@/api/produce/report/collectsummary'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'collectsummary',
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectLocal,
    SelectRemote
  },
  data () {
    return {
      activeName: 'goods',
      billTypeList: [
        {
          billTypeName: '生产领料单',
          billTypeId: '170202'
        },
        {
          billTypeName: '生产退料单',
          billTypeId: '170205'
        }
      ], //单据类型
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'goodsNos', name: '商品编码' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      activeNameType: {
        goods: 'multipleTable1',
        store: 'multipleTable2',
        dept: 'multipleTable3'
      }, //枚举
      exportType: {
        goods: '/api/system/produce/report/exportPickingSummaryByGoods',
        store: '/api/system/produce/report/exportPickingSummaryByStore',
        dept: '/api/system/produce/report/exportPickingSummaryByDept'
      }, //定义导出枚举
      exportNameType: {
        goods: '生产领料汇总表（商品汇总查询）',
        store: '生产领料汇总表（仓库汇总查询）',
        dept: '生产领料汇总表（生产工厂汇总查询）'
      }, //定义导出名称枚举
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs[this.activeNameType[this.activeName]].doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    // 分页
    handleClick (tab) {
      this.getList()
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      let res = {}
      if (this.activeName == 'goods') {
        res = await summaryByGoodsAPI(this.queryParams)
      } else if (this.activeName == 'store') {
        res = await summaryByStoreAPI(this.queryParams)
      } else {
        res = await summaryByDeptAPI(this.queryParams)
      }

      this.tableData = res.rows
      this.total = res.total
      this.loadingTable = false
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (this.activeName == 'goods') {
          if (index <= 6 || index == 10 || index == 11) {
            sums[index] = ''
            return
          }
        } else if (this.activeName == 'store' || this.activeName == 'dept') {
          if (index <= 7 || index == 11 || index == 12) {
            sums[index] = ''
            return
          }
        }

        let values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${Number(sums[index].toFixed(2))} `
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 96px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
