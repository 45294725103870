<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  listDistributeByGoods, // 产品分货按商品汇总查询
  listDetails, // 查询产品分货明细列表
  listDistributeByProduceDept // 产品分货按生产工厂汇总查询
} from '@/api/produce/report/distributeTotal' //产品分货统计表
export default {
  name: 'PassengerFlow',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '组装拆卸查询',
        rowKey: 'shopId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '全部/商品编号/商品名称/条码',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编码' },
              { filter: 'goodsNames', label: '商品名称' },
              { filter: 'barcodes', label: '商品条码' }
            ]
            // hide: () => ["assemblyDetail"].includes(this.options.tabColumnType),
          },
          {
            label: '单据编号',
            seniorSearch: true,
            type: 'input',
            model: '',
            filter: 'billNo'
          },
          this.$select({
            key: 'listGoods',
            option: {
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          this.$select({
            key: 'listProduceDept',
            option: {
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          this.$select({
            key: 'listStore',
            option: {
              label: '分货对象',
              seniorSearch: true,
              filter: "orderStoreIds",
              option: { multiple: true }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'receipt',
        tabsColumns: [
          {
            title: '产品分货明细查询',
            // getSummaryApi: async (e) => {
            //   const res = await sumListAssembleGoodsDetail(e);
            //   const [{ unitSaleMoney, unitPurMoney, unitQty } = {}] =
            //     res?.data?.details || [];
            //   return {
            //     ...res?.data,
            //     unitSaleMoney,
            //     unitPurMoney,
            //     unitQty,
            //   };
            // },
            getListApi: listDetails,
            exportOption: {
              fastExportUrl:
                '/api/system/produce/bill/goods/distribute/report/exportDistributeDetails',
              exportName: '产品分货明细查询'
            },
            columns: [
              {
                prop: 'billDate',
                label: '单据日期',
                minWidth: 155
              },
              {
                prop: 'billNo',
                label: '单据编号',
                // type: "link",
                // click: "update",
                minWidth: 150,
                align: 'center'
              },
              {
                prop: "distributeTotalQty",
                label: "分货数",
                minWidth: 150,
              },
              {
                prop: "alreadyDistributeTotalQty",
                label: "已分货数",
                minWidth: 150,
              },
              {
                prop: 'orderQty',
                label: '订货数',
                minWidth: 150
              },
              {
                prop: 'orderStoreName',
                label: '分货对象',
                minWidth: 150
              },
              {
                prop: 'produceDeptNo',
                label: '生产工厂编号',
                minWidth: 150
              },
              {
                prop: 'produceDeptName',
                label: '生产工厂',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '基本单位',
                minWidth: 150
              }
              // {
              //   prop: "goodsSpec",
              //   label: "基本单位数量",
              //   minWidth: 150,
              // },
              // {
              //   prop: "goodsSpec",
              //   label: "单位",
              //   minWidth: 150,
              // },
              // {
              //   prop: "goodsSpec",
              //   label: "数量",
              //   minWidth: 150,
              // },
            ],
            summary: [
              "orderQty",
              "distributeTotalQty",
              "alreadyDistributeTotalQty",
            ],
          },
          {
            title: '生产工厂汇总查询',
            getListApi: listDistributeByProduceDept,
            exportOption: {
              fastExportUrl:
                '/api/system/produce/bill/goods/distribute/report/exportDistributeByProduceDept',
              exportName: '生产工厂汇总查询'
            },
            columns: [
              // {
              //   prop: "billDate",
              //   label: "部门编码",
              //   minWidth: 155,
              // },
              {
                prop: 'produceDeptNo',
                label: '生产工厂编号',
                minWidth: 150,
                align: 'center'
              },
              // {
              //   prop: "produceDeptNo",
              //   label: "生产工厂编号",
              //   minWidth: 150,
              // },
              {
                prop: 'produceDeptName',
                label: '生产工厂',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '基本单位',
                minWidth: 150
              },
              // {
              //   prop: "unitName",
              //   label: "基本单位数量",
              //   minWidth: 150,
              // },
              {
                prop: 'distributeQty',
                label: '分货数',
                minWidth: 150
              },
              {
                prop: 'alreadyDistributeQty',
                label: '已分货数',
                minWidth: 150
              },
              {
                prop: 'orderQty',
                label: '订货数',
                minWidth: 150
              },
              {
                prop: 'orderStoreName',
                label: '分货对象',
                minWidth: 150
              }
            ],
            summary: ['orderQty', 'alreadyDistributeQty', 'distributeQty']
          },
          {
            title: '商品汇总查询',
            getListApi: listDistributeByGoods,
            exportOption: {
              fastExportUrl:
                '/api/system/produce/bill/goods/distribute/report/exportDistributeByGoods',
              exportName: '商品汇总查询'
            },
            columns: [
              // {
              //   prop: "billDate",
              //   label: "部门编码",
              //   minWidth: 155,
              // },
              // {
              //   prop: "produceDeptNo",
              //   label: "生产工厂编号",
              //   minWidth: 150,
              // },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '基本单位',
                minWidth: 150
              },
              {
                prop: 'distributeQty',
                label: '分货数',
                minWidth: 150
              },
              {
                prop: 'alreadyDistributeQty',
                label: '已分货数',
                minWidth: 150
              },
              {
                prop: 'orderQty',
                label: '订货数',
                minWidth: 150
              },
              {
                prop: 'orderStoreName',
                label: '分货对象',
                minWidth: 150
              }
              // {
              //   prop: "unitName",
              //   label: "基本单位数量",
              //   minWidth: 150,
              // },
            ],
            summary: ['orderQty', 'alreadyDistributeQty', 'distributeQty']
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'update':
          const billId = row.billId
          const activeTab = this.options.tabColumnType
          let routePath = ''
          let query = {}
          if (activeTab === 'receipt') {
            routePath = '/shop/bill/order/OrderDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'materialOrder') {
            routePath = '/shop/bill/materialOrder/materialOrderDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'purchase') {
            routePath = '/shop/bill/stockIn/StockInDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'scrap') {
            routePath = '/shop/bill/scrap/ScrapDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'shopIn') {
            routePath = '/shop/bill/moveIn/MoveInDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'shopOut') {
            routePath = '/shop/bill/moveOut/MoveOutDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'produce') {
            routePath = '/shop/bill/produce/ProduceDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'picking') {
            routePath = '/shop/bill/waste/WasteDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          } else if (activeTab === 'check') {
            routePath = '/shop/bill/check/CheckDetail'
            query = {
              billId: billId,
              type: 'Update'
            }
          }
          this.$router.push({
            path: routePath,
            query: query
          })
          break
        default:
          break
      }
    }
  }
}
</script>
