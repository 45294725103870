var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap x-f", attrs: { id: "wrap" } },
    [
      _c("categoryTreeCard", {
        staticStyle: { "margin-right": "0" },
        attrs: {
          categoryTreeTitle: "生产工厂",
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.produceDeptList,
          defaultProps: _vm.defaultProps,
        },
        on: { treeNodeId: _vm.getTreeNodeId },
      }),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.availableLoading,
                expression: "availableLoading",
              },
            ],
            staticClass: "content",
          },
          [
            _c(
              "cardTitleCom",
              { staticClass: "cardDetail", attrs: { cardTitle: "生产组" } },
              [
                _c("template", { slot: "cardContent" }, [
                  _c(
                    "div",
                    { staticClass: "tableContent" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: { rules: _vm.rules, model: _vm.formDetail },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingDetail,
                                  expression: "loadingDetail",
                                },
                              ],
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.tableDataDetail,
                                border: "",
                                "max-height": _vm.tableHeight,
                                height: _vm.tableHeight,
                              },
                              on: {
                                "row-click": _vm.handleRowClick,
                                "cell-mouse-enter": function (row) {
                                  return _vm.cellMouseEnter(
                                    row,
                                    "tableDataDetail"
                                  )
                                },
                                "cell-mouse-leave": function ($event) {
                                  return _vm.cellMouseLeave("tableDataDetail")
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { width: "55", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: true },
                                            model: {
                                              value: scope.row.selected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "selected",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.selected",
                                            },
                                          },
                                          [_vm._v(_vm._s(""))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: _vm.tableCellLabel,
                                  width: "80",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.hoverRow
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-circle-plus operatePush",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.rowDetail(
                                                    "push",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        scope.row.hoverRow
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-remove operateDel",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.rowDetail(
                                                    "del",
                                                    scope.$index,
                                                    scope.row.produceGroupId
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        !scope.row.hoverRow
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.$index + 1) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产组编号",
                                  align: "center",
                                  prop: "produceGroupNo",
                                  "min-width": "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            maxlength: "20",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.blurGroup(scope.$index)
                                            },
                                            focus: function ($event) {
                                              return _vm.handleRowClick(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.produceGroupNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "produceGroupNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.produceGroupNo",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产组名称",
                                  align: "center",
                                  prop: "produceGroupName",
                                  "min-width": "150",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            maxlength: "40",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.blurGroup(scope.$index)
                                            },
                                            focus: function ($event) {
                                              return _vm.handleRowClick(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.produceGroupName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "produceGroupName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.produceGroupName",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "cardTitleCom",
              {
                staticClass: "cardAvailable",
                attrs: { cardTitle: _vm.selectGroupName },
              },
              [
                _c(
                  "template",
                  { slot: "rightCardTitle" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              path: "/produce/sets/produceGroup/goods",
                            })
                          },
                        },
                      },
                      [_vm._v("生产组商品查询")]
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "cardContent" }, [
                  _c(
                    "div",
                    { staticClass: "tableContent" },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            "tooltip-effect": "dark",
                            data: _vm.tableDataAvailable,
                            border: "",
                            "max-height": _vm.tableHeight,
                            height: _vm.tableHeight,
                          },
                          on: {
                            "cell-mouse-enter": function (row) {
                              return _vm.cellMouseEnter(
                                row,
                                "tableDataAvailable"
                              )
                            },
                            "cell-mouse-leave": function ($event) {
                              return _vm.cellMouseLeave("tableDataAvailable")
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.tableCellLabel,
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.hoverRow
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus operatePush",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.rowAvailable(
                                                "push",
                                                scope.$index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    scope.row.hoverRow
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-remove operateDel",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.rowAvailable(
                                                "del",
                                                scope.$index,
                                                scope.row.goodsId
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    !scope.row.hoverRow
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品编码",
                              align: "center",
                              prop: "goodsNo",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { disabled: _vm.disabled },
                                        model: {
                                          value: scope.row.goodsNo,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "goodsNo", $$v)
                                          },
                                          expression: "scope.row.goodsNo",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-more more",
                                          attrs: { slot: "suffix" },
                                          on: { click: _vm.selectGoods },
                                          slot: "suffix",
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品名称",
                              align: "center",
                              "min-width": "150",
                              prop: "goodsName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              align: "center",
                              prop: "goodsSpec",
                              "min-width": "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "单位",
                              align: "center",
                              prop: "unitName",
                              "min-width": "100",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }