<template>
  <div class="wrap x-f" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard
      categoryTreeTitle="生产工厂"
      @treeNodeId="getTreeNodeId"
      :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight"
      :categoryTreeOptions="produceDeptList"
      :defaultProps="defaultProps"
    />
    <div class="right">
      <!-- 关系情况 -->
      <cardTitleCom
        cardTitle="生产班次信息"
        class="content"
        v-loading="loading"
      >
        <template #rightCardTitle>
          <el-button
            class="marR10"
            type="primary"
            size="mini"
            @click="submitForm"
            >保存
          </el-button>
        </template>
        <template slot="cardContent">
          <div class="tableContent">
            <el-form ref="form" :rules="rules" :model="form">
              <el-table
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
              >
                <el-table-column
                  align="center"
                  :label="tableCellLabel"
                  width="80"
                >
                  <template v-slot="scope">
                    <i
                      v-if="scope.row.hoverRow"
                      @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      v-if="scope.row.hoverRow"
                      @click="
                        row('del', scope.$index, scope.row.produceOrderId)
                      "
                      class="el-icon-remove operateDel"
                    ></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="生产班次编号"
                  align="center"
                  prop="produceOrderNo"
                  min-width="160"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'tableData.' + scope.$index + '.produceOrderNo'"
                      :rules="rules[`produceOrderNo`]"
                    >
                      <el-input
                        size="mini"
                        maxlength="20"
                        class="inputWith"
                        v-model="scope.row.produceOrderNo"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="生产班次名称"
                  align="center"
                  prop="produceOrderName"
                  min-width="160"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'tableData.' + scope.$index + '.produceOrderName'"
                      :rules="rules[`produceOrderName`]"
                    >
                      <el-input
                        size="mini"
                        maxlength="40"
                        class="inputWith"
                        v-model="scope.row.produceOrderName"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="开始时间"
                  align="center"
                  prop="produceOrderBegTime"
                  min-width="160"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'tableData.' + scope.$index + '.produceOrderBegTime'
                      "
                      :rules="rules[`produceOrderBegTime`]"
                    >
                      <el-time-picker
                        size="mini"
                        v-model="scope.row.produceOrderBegTime"
                        placeholder="请选择开始时间"
                        value-format="HH:mm:ss"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="结束时间"
                  align="center"
                  prop="produceOrderEndTime"
                  min-width="160"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'tableData.' + scope.$index + '.produceOrderEndTime'
                      "
                      :rules="rules[`produceOrderEndTime`]"
                    >
                      <el-time-picker
                        size="mini"
                        v-model="scope.row.produceOrderEndTime"
                        placeholder="请选择结束时间"
                        value-format="HH:mm:ss"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </template>
      </cardTitleCom>
    </div>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import bottomPage from "@/views/components/bottomPage"; //底部分页
import SelectGoods from "@/views/components/selectGoodsUp"; //选择商品
import {
  queryProduceBasOrderList,
  produceOrderBatchSave,
} from "@/api/produce/sets/produceOrder"; //生产班次接口
import categoryTreeCard from "@/views/components/categoryTreeCard"; //选择类别
import { queryProduceBasDeptList } from "@/api/produce/sets/produceDept"; //生产仓库
export default {
  name: "produceOrder",
  components: { bottomPage, SelectGoods, cardTitleCom, categoryTreeCard },
  data() {
    return {
      loading: false, //加载
      defaultProps: {
        id: "produceDeptId",
        label: "produceDeptName",
      },
      produceDeptList: [],
      tableCellLabel: "序号",
      ids: [], // 选中数组
      form: {
        tableData: [{}], //表格数据
      }, //表单校验数据
      tableData: [{}], //表格数据
      rules: {
        produceOrderNo: [
          {
            required: true,
            message: "请输入生产班次编号",
            trigger: ["blur", "change"],
          },
        ],
        produceOrderName: [
          {
            required: true,
            message: "请输入生产班次名称",
            trigger: ["blur", "change"],
          },
        ],
        produceOrderBegTime: [
          {
            required: true,
            message: "请输入生产班次开始时间",
            trigger: ["blur", "change"],
          },
        ],
        produceOrderEndTime: [
          {
            required: true,
            message: "请输入生产班次结束时间",
            trigger: ["blur", "change"],
          },
        ],
      }, //校验
      // 查询参数
      queryParams: { pageNum: 1, pageSize: 99999 },
      vivwH: 0, //页面高度
    };
  },
  watch: {
    tableData: {
      handler: function (newVal, oldVal) {
        // 处理数组变化的逻辑
        this.form.tableData = this.tableData;
      },
      deep: true, // 开启深度监听
    },
  },
  async created() {
    const res = await queryProduceBasDeptList();
    this.produceDeptList = res.data;
  },
  computed: {
    tableHeight() {
      return this.vivwH - 70;
    },
    boxHeight() {
      return this.vivwH - 10;
    },
    boxInternalHeight() {
      return this.vivwH - 38;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //点击类别树
    async getTreeNodeId(value) {
      this.queryParams.produceDeptId = value;
      await this.getList();
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      let eleIndex = this.tableData.indexOf(row);
      for (let index = 0; index < this.tableData.length; index++) {
        const element = this.tableData[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.tableData.length; index++) {
        const element = this.tableData[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    //刷新列表接口
    async getList() {
      try {
        if (!this.loading) {
          this.loading = true;
        }
        const res = await queryProduceBasOrderList(this.queryParams);
        this.tableData = res.data;
        if (this.tableData.length == 0 || !this.tableData) {
          this.tableData = [{}];
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //确定
    submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.tableData.forEach((v) => {
            v.produceDeptId = this.queryParams.produceDeptId;
          });
          try {
            this.loading = true;
            await produceOrderBatchSave(this.tableData);
            await this.getList();
            this.$message({
              type: "success",
              message: "保存生产班次成功!",
            });
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
    //原料明细表格当中增加/减少一行方法
    row(name, index, produceOrderId) {
      if (name === "push") {
        this.tableData.splice(index + 1, 0, {});
      } else {
        if (this.tableData.length <= 1 && !produceOrderId) return;
        this.$confirm("此操作将删除该生产班次, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.tableData.splice(index, 1);
          })
          .catch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 最大盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);

  .right {
    width: calc(100% - 354px); //生产班次数组
    //关系情况
    .content {
      margin-bottom: 0;
      .tableContent {
        margin: 6px 10px 10px 10px;
      }
    }
  }
}
::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep .el-form-item__error {
  position: static;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>
